import React from "react"
import Layout from "../../components/layout"

const Single = ({ children, heading }) => {
  return (
    <Layout>
      <div className="grid grid-cols-12 gap-gs px-ogs pt-8 sm:pt-12 md:pt-18 pb-18">
        {heading && (
          <div className="col-span-12 md:col-span-4">
            <h1 className="heading-alpha max-w-xs">{heading}</h1>
          </div>
        )}
        <div className="col-span-12 md:col-span-8">
          <div className="max-w-4xl">{children}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Single
