import React from "react"
import Link from "../../components/link"

const SingleContent = ({ body, button }) => {
  return (
    <>
      {body?.html && (
        <div
          className="rich-text mb-14 sm:mb-18"
          dangerouslySetInnerHTML={{
            __html: body.html,
          }}
        ></div>
      )}
      <div className="hidden sm:block space-y-4">
        {button?.link?.url && (
          <div>
            <Link className="btn-primary btn-primary--lg" data={button.link}>
              {button.title}
            </Link>
          </div>
        )}
        <div>
          <Link
            className="btn-primary btn-primary--lg btn-primary--blue"
            data={{ uid: "sign-up", link_type: "Document" }}
          >
            Keep me informed on future updates
          </Link>
        </div>
      </div>
      <div className="sm:hidden space-y-2">
        {button?.link?.url && (
          <div>
            <Link className="btn-primary" data={button.link}>
              {button.title}
            </Link>
          </div>
        )}
        <div>
          <Link
            className="btn-primary btn-primary--blue"
            data={{ uid: "sign-up", link_type: "Document" }}
          >
            Keep me informed on future updates
          </Link>
        </div>
      </div>
    </>
  )
}

export default SingleContent
